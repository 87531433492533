body {
    height:100%;
    overflow:hidden;
    margin:0;
    padding:0;
    font-family:Roboto, Arial, sans-serif;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size:16px;
    color: #646464;
    background-color:#fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#krPanoiFrame {
    -webkit-box-shadow: 0px 0px 38px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 38px 2px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 38px 2px rgba(0,0,0,0.75);
}
